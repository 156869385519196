import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from 'axios'

export default function Unstake() {
    const { showmenu, settings, account, formatAddress, copyaddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "unstake",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize,
            })
            .then((res) => {
                // console.log("ress", res.data);
                // var dedata = decryptData(res.data);
                setisLoading(false);

                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='pt-5'>
                        {/*for demo wrap*/}
                        <div className='d-flex justify-content-between'>
                            <h1>Unstake</h1>
                            <div className="button">
                                <a
                                    href={`${process.env.REACT_APP_EXPLORER}address/${settings.contract}#writeContract#F1`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn-action style-2 mb-3 "
                                    type="button"
                                >
                                    Unstake now
                                </a>

                            </div>
                        </div>
                        <div className='table-main'>
                            <div className="tbl-header">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>Hash</th>
                                            <th>Amount</th>
                                            <th>Tokens</th>
                                            <th>Rate</th>
                                            <th>Unstake Date</th>
                                            <th>Release Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    {isLoading ? "Data is loading" : ""}
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={7}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    <tbody className="nftmax-table__body">
                                        {data.map((ele, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {ele.id}
                                                    </td>
                                                    <td>
                                                        <div >
                                                            {ele.unstake_hash ?
                                                                <span onClick={() =>
                                                                    copyaddress(ele.unstake_hash)}>
                                                                    {formatAddress(ele.unstake_hash)} <i className="fa fa-copy"></i>
                                                                </span>
                                                                : ''}
                                                        </div>
                                                    </td>
                                                    <td>    $ {ele.amount}
                                                    </td>
                                                    <td>
                                                        {ele.tokens} {process.env.REACT_APP_TICKER}
                                                    </td>
                                                    <td>
                                                        $ {ele.rate}
                                                    </td>
                                                    <td>
                                                        {ele.createdAt}
                                                    </td>
                                                    <td>
                                                        {ele.unstake_date}
                                                    </td>
                                                    <td>
                                                        {ele.status ? (
                                                            <div className="nftmax-table__status nftmax-sbcolor">
                                                                Success
                                                            </div>
                                                        ) : (
                                                            <div className="nftmax-table__status nftmax-rbcolor">
                                                                Pending
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
