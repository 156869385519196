import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from "axios";
import Pagination from '../Comman/Pagination';

export default function GlobalReward() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)

    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "global",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='pt-5'>
                        {/*for demo wrap*/}
                        <h1>Noovko Reward</h1>
                        <div className='table-main'>
                            <div className="tbl-header">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <thead>
                                        <tr>
                                            <th> Sr</th>
                                            <th> Level</th>
                                            <th> Team Income</th>
                                            <th>Income(%)</th>
                                            <th>Total Users</th>
                                            <th>Today Fees</th>
                                            {/* <th> Status</th> */}
                                            <th> Time</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="tbl-content">
                                <table cellPadding={0} cellSpacing={0} border={0}>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={7}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    <tbody>
                                        {data.map((ele, i) => {
                                            return (
                                                <tr>
                                                    <td >
                                                        {ele.id}
                                                    </td>
                                                    <td >
                                                        {ele.level}
                                                    </td>
                                                    <td >
                                                        $ {ele.team_income}
                                                    </td>
                                                    <td >
                                                        $ {ele.income} ({ele.income_per} %)
                                                    </td>
                                                    <td >
                                                        {ele.total_users}
                                                    </td>
                                                    <td >
                                                        $ {ele.todaysfee}
                                                    </td>
                                                    {/* <td>
                                                    {ele.flushed ? "Flushed" : ""}
                                                </td> */}
                                                    <td >
                                                        {ele.createdAt}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pages={pages}
                        />
                    </section>
                </div>
            </div>
        </>
    )
}
