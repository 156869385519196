import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { ProjectContext } from '../../ProjectContext'
import DashBoardHeader from './DashBoardHeader'
import axios from 'axios'

export default function Withdrawal() {
    const { showmenu, account,
        toastSuccess, dbbalance,
        tokenrate,
        toastError,
        copyaddress, formatAddress, getuserDB,
        connectMetamask,

    } = useContext(ProjectContext)
    const [amount, setamount] = useState(0);
    const [rcv_amount, setrcv_amount] = useState(0);
    const [fees, setfees] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);

    const [data, setdata] = useState([]);
    const [datap, setdatap] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                console.log("vvsdf", res.data);
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    const getpData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getpbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdatap(res.data.data);
            });
    };

    const withdraw = async () => {
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (amount < 15) {
            toastError("Min $15 required");
            settrdone(false);
            return;
        }
        if (amount > dbbalance) {
            toastError("Insufficient Balance");
            settrdone(false);
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                address: account,
                amount: amount,
                token: total_tokens,
                fee: fees,
                t_rcv: rcv_amount,
                rate: tokenrate,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
            });
        getuserDB();
        getpData()
        getData()
        settrdone(false);
    }
    useEffect(() => {
        var tmt = amount / tokenrate;
        var tmtfee = (tmt * 10) / 100;
        settotal_tokens(tmt);
        setfees(tmtfee);
        setrcv_amount(tmt - tmtfee);
    }, [amount, tokenrate]);
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <div className={!showmenu ? 'dashboard page-loaded show' : 'dashboard page-loaded'}>
                <Sidebar />

                <div className="dashboard__content">
                    <DashBoardHeader />
                    <section className='sign-in'>
                        {/*for demo wrap*/}
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sign-in__main">
                                        <div className="top center">
                                            <h3 className="title text-white">Withdraw now</h3>
                                            <p className="fs-17 text-white">Min: $15</p>
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <div className='d-flex justify-content-between'>
                                                    <label className='text-white' htmlFor="amountnnvvk">Amount</label>
                                                    <label className='text-white' htmlFor="amountnnvvk">Balance:$ {dbbalance}</label>
                                                </div>
                                                <input type="text" className="form-control" id="amountnnvvk" value={amount}
                                                    onChange={(e) =>
                                                        setamount(e.target.value)
                                                    } placeholder="0" />
                                            </div>

                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Rate</label>
                                                </div>
                                                <p className='text-white'>${tokenrate}</p>
                                            </div>
                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Token</label>
                                                </div>
                                                <p className='text-white'>{total_tokens}{" "}
                                                    {process.env.REACT_APP_TICKER}</p>
                                            </div>
                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Fee</label>
                                                </div>
                                                <p className='text-white'>{fees}{" "}
                                                    {process.env.REACT_APP_TICKER} (10%)</p>
                                            </div>
                                            <div className="form-check">
                                                <div className="left">
                                                    <label className="form-check-label text-white" htmlFor="exampleCheck1">Receive Token</label>
                                                </div>
                                                <p className='text-white'>{rcv_amount}{" "}
                                                    {process.env.REACT_APP_TICKER}</p>
                                            </div>
                                            <button type="button" className="btn-action style-1" onClick={() => withdraw()}><span>Withdraw</span> </button>
                                        </form>
                                    </div>
                                    {/* <p className="bottom">Dont have an account? <a href="sign-up.html">Sign up</a></p> */}
                                </div>
                                {datap!==null ?
                                <div className="container pt-2">
                                    <h1>Pending Withdrawal</h1>
                                    <div className='table-main'>
                                        <div className="tbl-header">
                                            <table cellPadding={0} cellSpacing={0} border={0}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Amount</th>
                                                        <th>Token</th>
                                                        <th>Fee</th>
                                                        <th>Total Recieve</th>
                                                        <th>Rate</th>
                                                        <th>Datetime</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="tbl-content">
                                            <table cellPadding={0} cellSpacing={0} border={0}>
                                                
                                                <tbody>
                                                    {datap.map((ele, i) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {ele.id}
                                                                </td>
                                                                <td >
                                                                    $ {ele.amount}
                                                                </td>

                                                                <td >
                                                                    {ele.token} {process.env.REACT_APP_TICKER} 
                                                                </td>
                                                                <td >
                                                                    {ele.fee} {process.env.REACT_APP_TICKER} (10%)
                                                                </td>
                                                                <td >
                                                                    {ele.t_rcv} {process.env.REACT_APP_TICKER}
                                                                </td>
                                                                <td >
                                                                    $ {ele.rate}
                                                                </td>
                                                                <td > {ele.datetime}
                                                                </td>
                                                                <td className='text-warning'> Pending
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :''}
                                <div className="container pt-5">
                                    <h1>Withdrawal</h1>
                                    <div className='table-main'>
                                        <div className="tbl-header">
                                            <table cellPadding={0} cellSpacing={0} border={0}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Amount</th>
                                                        <th>Token</th>
                                                        <th>Fee</th>
                                                        <th>Total Recieve</th>
                                                        <th>Rate</th>
                                                        <th>Datetime</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="tbl-content">
                                            <table cellPadding={0} cellSpacing={0} border={0}>
                                                <tr className="text-center">
                                                    {isLoading ? "Data is loading" : ""}
                                                </tr>
                                                {!isLoading ? (
                                                    !data.length ? (
                                                        <tr className="text-center">
                                                            <td className="text-center" colSpan={7}>
                                                                <span className="w-100">No data found</span>
                                                            </td>
                                                        </tr>

                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                <tbody>
                                                    {data.map((ele, i) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {ele.id}
                                                                </td>
                                                                <td >
                                                                    $ {ele.amount}
                                                                </td>

                                                                <td >
                                                                    {ele.token} {process.env.REACT_APP_TICKER} 
                                                                </td>
                                                                <td >
                                                                    {ele.fee} {process.env.REACT_APP_TICKER} (10%)
                                                                </td>
                                                                <td >
                                                                    {ele.t_rcv} {process.env.REACT_APP_TICKER}
                                                                </td>
                                                                <td >
                                                                    $ {ele.rate}
                                                                </td>
                                                                <td > {ele.datetime}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </div>
            </div>
        </>
    )
}
