import React, { useContext, useState, useEffect } from 'react'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Hero() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress, tokenrate } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "dashd",
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                // console.log("dashboard ddata", res.data.data);
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    return (
        <>
            <section className="banner-top style-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner-top__content">
                                <h2 className="title">The One-step
                                    <span> Crypto Payment</span> Solution.</h2>
                                <p className="desc">Noovko enables paying crypto anywhere by instant crypto-to-fiat conversion
                                </p>
                                <div className="button">
                                    <a href="https://noovko.gitbook.io/noovko-whitepaper" target='_blank' className="btn-action style-2"><span>Whitepaper </span></a>
                                </div>
                                <ul className="content-list">
                                    {/* <li>
                                        <img src="./assets/images/payment_partners.png" alt="" />
                                        <h5>+10</h5>
                                        <p>Payment Partners</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/payments_processed.png" alt="" />
                                        <h5>+10,000</h5>
                                        <p>CryptoItem</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/cumulative_volume.png" alt="" />
                                        <h5>+120{"K"}</h5>
                                        <p>Cumulative Volume</p>
                                    </li> */}
                                    <li>
                                        <img src="./assets/images/cumulative_volume.png" alt="" />
                                        <h5>${data.t_staking}</h5>
                                        <p>Total Stake</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/payments_processed.png" alt="" />
                                        <h5>${data.t_withdrawal}</h5>
                                        <p>Total Withdrawal</p>
                                    </li>
                                    <li>
                                        <img src="./assets/images/payment_partners.png" alt="" />
                                        <h5>${tokenrate}</h5>
                                        <p>Rate</p>
                                    </li>
                                    {/* <li>
                                        <img src="./assets/images/cumulative_volume.png" alt="" />
                                        <h5>+120{"K"}</h5>
                                        <p>TVL</p>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="banner-top__sale">
                                <img src="assets/images/hero.png" alt="ICOLand" />
                                {/* <div className="sale-content">
                                    <h6>Token sale End In!</h6>
                                    <span className="js-countdown" data-timer={1865550} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
