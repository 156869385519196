import React from 'react'

export default function Roadmap() {
    return (
        <>
            <section className="road-map style-2" id='Roadmap'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3>Roadmap</h3>
                                <p className="mt-12">The use of cryptocurrencies has become more widespread, The origin platform idea.<br /> Development of the concept and business plan.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="roadmap__main" style={{overflow:"visible"}}>
                                <div className="box-roadmap bottom">
                                    <div className="title"><h5 className="fs-24">2024 <span>H1</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-gene" /><p>Research & Planning</p></li>
                                            <li><span className="icon-gene" /><p>Token Launch</p></li>
                                            <li><span className="icon-gene" /><p>Community Building</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap top">
                                    <div className="title"><h5 className="fs-24">2024 <span>H2</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Staking & Rewards Program</p></li>
                                            <li><span className="icon-gene" /><p>Marketing Campaigns</p></li>
                                            <li><span className="icon-gene" /><p>Governance Model</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap bottom">
                                    <div className="title"><h5 className="fs-24">2025 <span>H1 </span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Additional Crypto Payments Options</p></li>
                                            <li><span className="icon-gene" /><p>Partnerships & Collaborations</p></li>
                                            <li><span className="icon-gene" /><p>Integration of Fiat Onramps</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap top">
                                    <div className="title"><h5 className="fs-24">2025 <span>H2</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Expansion to New Markets</p></li>
                                            <li><span className="icon-boxs" /><p>Development of Mobile App Beta Version</p></li>
                                            <li><span className="icon-boxs" /><p>Launch of Noovko Wallet</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap bottom">
                                    <div className="title"><h5 className="fs-24">2025 <span>Q2</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Private closed beta</p></li>
                                            <li><span className="icon-gene" /><p>Open beta launched to public and improvement the app</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap top">
                                    <div className="title"><h5 className="fs-24">2026 <span>H1 </span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Introduction of Blockchain Infrastructure</p></li>
                                            <li><span className="icon-gene" /><p>Continued Innovation and Growth</p></li>
                                            <li><span className="icon-gene" /><p>Launch of Noovko Exchange</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap bottom">
                                    <div className="title"><h5 className="fs-24">2026 <span>H2</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Launch of Noovko Payment Cards</p></li>
                                            <li><span className="icon-gene" /><p>Expansion of Developer Ecosystem</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-roadmap top">
                                    <div className="title"><h5 className="fs-24">2027 <span>H1</span></h5></div>
                                    <div className="content">
                                        <h5></h5>
                                        <ul className="list">
                                            <li><span className="icon-boxs" /><p>Blockchain Mainnet </p></li>
                                            <li><span className="icon-gene" /><p>Focus on Sustainability and Social Impact</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
