import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './Components/Dashboard/Dashboard';
import Team from './Components/Dashboard/Team';
import Stake from './Components/Dashboard/Stake';
import Staking from './Components/Dashboard/Staking';
import StakingReward from './Components/Dashboard/StakingReward';
import DirectReward from './Components/Dashboard/DirectReward';
import BoosterReward from './Components/Dashboard/BoosterReward';
import GenretorReward from './Components/Dashboard/GenretorReward';
import RankReward from './Components/Dashboard/RankReward';
import RoyaltyReward from './Components/Dashboard/RoyaltyReward';
import GlobalReward from './Components/Dashboard/GlobalReward';
import Withdrawal from './Components/Dashboard/Withdrawal';
import Unstake from './Components/Dashboard/Unstake';
import PromotersReward from './Components/Dashboard/PromotersReward';
import Transparency from './Components/Transparency';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/team" element={<Team />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/stake/:link_ref_address" element={<Stake />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/staking-reward" element={<StakingReward />} />
          <Route path="/direct-reward" element={<DirectReward />} />
          <Route path="/booster-reward" element={<BoosterReward />} />
          <Route path="/generator-reward" element={<GenretorReward />} />
          <Route path="/rank-reward" element={<RankReward />} />
          <Route path="/royalty-reward" element={<RoyaltyReward />} />
          <Route path="/global-reward" element={<GlobalReward />} />
          <Route path="/promoters-reward" element={<PromotersReward />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/unstake" element={<Unstake />} />
          <Route path="/transparency" element={<Transparency />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;