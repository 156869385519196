import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Comman/Header'
import Footer from './Comman/Footer'

export default function Transparency() {
    return (
        <>
            <Header />
            <section className="about style-3" id='Tokenomics'>
                <div className="container">
                    <div className="about__content text-center mb-4">
                        <h6 className="sub-title">Transparency</h6>
                        <h3 className="title">Noovko Transparency</h3>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-8 col-md-12">
                            <div className='table-main'>
                                <div className="tbl-header">
                                    <table cellPadding={0} cellSpacing={0} border={0}>
                                        <thead>
                                            <tr>
                                                <th> Sr.</th>
                                                <th>Name</th>
                                                <th>Contract</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div className="tbl-content">
                                    <table cellPadding={0} cellSpacing={0} border={0}>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>Noovko Token</td>
                                                <td><Link to="https://bscscan.com/address/0x2faa377ac62d049e14769402026785a8a3d8d60c" target='_blank'> 0x2faa377ac62d049e14769402026785a8a3d8d60c </Link></td>
                                            </tr>
                                            <tr>
                                                <td>2.</td>
                                                <td>Noovko Ecosystem</td>
                                                <td><Link to="https://bscscan.com/address/0x339828C986EEA9A70003A6C8060a859A35f8Cb3F" target='_blank'>0x339828C986EEA9A70003A6C8060a859A35f8Cb3F </Link></td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Noovko Community</td>
                                                <td><Link to="https://bscscan.com/address/0x286169af9fd29eaaba8f339030af984033d9b8b4" target='_blank'>0x286169af9fd29eaaba8f339030af984033d9b8b4 </Link></td>
                                            </tr>
                                            <tr>
                                                <td>4.</td>
                                                <td>Noovko Marketing</td>
                                                <td><Link to="https://bscscan.com/address/0xff123876cb408af78821390e297681cb6c6bc806" target='_blank'>0xff123876cb408af78821390e297681cb6c6bc806 </Link></td>
                                            </tr>
                                            <tr>
                                                <td>5.</td>
                                                <td>Noovko Pramoters</td>
                                                <td><Link to="https://bscscan.com/address/0x1152d70b9587d92294ec8025e00f4f949eaccede" target='_blank'>0x1152d70b9587d92294ec8025e00f4f949eaccede </Link></td>
                                            </tr>
                                            <tr>
                                                <td>6.</td>
                                                <td>Noovko Liquidity </td>
                                                <td><Link to="https://bscscan.com/address/0xe7fc699de04b1e690433db21ebed389428edb1d3" target='_blank'>0xe7fc699de04b1e690433db21ebed389428edb1d3 </Link></td>
                                            </tr>
                                            <tr>
                                                <td>7.</td>
                                                <td>Noovko Community Staking and rewards</td>
                                                <td><Link to="https://bscscan.com/address/0x3715a43850f2a3effd08cf2ee65e51216dd2fced" target='_blank'>0x3715a43850f2a3effd08cf2ee65e51216dd2fced </Link></td>
                                            </tr>
                                            <tr>
                                                <td>8.</td>
                                                <td>Noovko Affiliate </td>
                                                <td><Link to="https://bscscan.com/address/0x9837081aeb804856c605fc7bb5646027d0d6f0a2" target='_blank'>0x9837081aeb804856c605fc7bb5646027d0d6f0a2 </Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
