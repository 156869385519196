import React, { useState, useContext } from 'react'
import { ProjectContext } from '../../ProjectContext'
import { Link, NavLink } from 'react-router-dom';

export default function Header() {
    const { account, connectMetamask, copyaddress, dbuser, formatAddress, disconnectNow } = useContext(ProjectContext);

    return (
        <>
            <header id="header_main" className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__body">
                                <div className="header__logo">
                                    <a href="/">
                                        <img id="site-logo" src="assets/images/logo/logo.png" alt="Noovko" width={165} height={40} data-retina="assets/images/logo/logo-main@x2.png" data-width={165} data-height={40} />
                                    </a>
                                </div>
                                <div className="header__right">
                                    <nav id="main-nav" className="main-nav">
                                        <ul id="menu-primary-menu" className="menu">
                                            <li className="menu-item">
                                                <a href="#About">About</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#Tokenomics">Tokenomics</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="#Roadmap">Roadmap</a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="https://noovko.gitbook.io/noovko-whitepaper" target='_blank'>Whitepaper</a>
                                            </li>
                                            <li className="menu-item">
                                                <NavLink to="/transparency">Transparency</NavLink>
                                            </li>
                                            <li className="menu-item d-lg-none d-block">
                                                <a type='button' onClick={disconnectNow}>Logout</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="group-button">
                                        {account ?
                                            <>
                                                <Link to="/dashboard" className="btn-action-outline style-1">
                                                    <span>{formatAddress(account)}</span>
                                                </Link>
                                                <Link type='button' className="btn-action-outline style-1" onClick={() => disconnectNow()}><span className="icon-wallet" /><span> Logout</span></Link>
                                            </>
                                            :
                                            <a href="#" className="btn-action-outline style-1" onClick={() => connectMetamask()}>
                                                <span>Connect Wallet</span>
                                            </a>}
                                    </div>
                                    <div className="d-lg-none d-block">
                                        {account ?
                                            <>
                                                <Link to="/dashboard" className="btn-action-outline style-1">
                                                    <span className="icon-users" />
                                                </Link>
                                            </>
                                            :
                                            <a href="#" className="btn-action-outline style-1" onClick={() => connectMetamask()}>
                                                <span className="icon-wallet" />
                                            </a>}
                                    </div>
                                    <div className="mobile-button"><span /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}
